import '../assets/js/battle'
import{ useEffect } from "react";
import { API } from "aws-amplify";
import "./Arena.css"

function Arena () {

  const getTanks = async () => {
    var battle_tanks =  await API.get("tanksApi", `/arenas/deacons/tanks/source`)

      var canvas = document.getElementById('battle_arena');
      var battle:Battle = new Battle(
        canvas.getContext("2d"),
        canvas.width,
        canvas.height,
        battle_tanks
      );
      battle.run();

  }

  useEffect(() => {
    getTanks()
  },[])


  return (
    <>
      <canvas  width={800} height={600} id="battle_arena"></canvas>
    </>
  );
}

export default Arena;