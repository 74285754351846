import './Home.css'

import { Col, Row, Form } from "react-bootstrap";

import "ace-builds";
import Bosses from "../services/bosses"
import AceEditor from "react-ace";
import '../assets/js/battle'
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/ext-language_tools";
import{ useEffect, useCallback, useState } from "react";
import { API } from "aws-amplify";
import LoaderButton from './LoaderButton';

function Home () {
  let disabled = false
  const bosses= new Bosses()
  const [isLoading, setIsLoading] = useState(true);

  const defaultBot = `
prototype.onIdle = function(){
  this.turn_turret_right(45);
};

prototype.onWallCollide = function(){};

prototype.onHit = function(){ 
  this.yell("Oops!");
};

prototype.onEnemySpot = function(){
  this.yell("Fire!");
  this.shoot();
};`;
const [name, setName] = useState();
const [code, setCode] = useState(defaultBot);
const [id, setId] = useState(null);

var customCompleter = {
  getCompletions: function(editor, session, pos, prefix, callback) {
    var wordList = ["this.turn_left();", "this.turn_right();", "this.move_forwards();","this.move_backwards();","this.move_opposide();","this.turn_turret_left();","this.turn_turret_right();","this.shoot();","this.yell();"];
    callback(null, wordList.map(function(word) {
        return {
            caption: word,
            value: word,
            meta: "static",
            completer: {
              insertMatch: function(editor, data) {
                  console.log(data.value); // This should give the completed keyword
                  editor.session.insert(editor.getCursorPosition(), data.value);
                  editor.gotoLine(pos.row + 1, pos.column + data.value.length);
              }
          }
        };
    }));
  }
}

const createTank = useCallback(async () => {
  const tank = await API.post("tanksApi", "/tanks",{
    body: {"name": name, "code": code},
  });
  return tank 
},[code, name])

const saveTank = async () => {
  setIsLoading(true)
  await API.put("tanksApi", `/tanks/${id}`,{
    body: {"name": name, "code": code},
  }).then(resp => {  setIsLoading(false)  });
}

const getTanks = useCallback(async () => {
  setIsLoading(true);
  const tanks = await API.get("tanksApi", "/tanks");
  if(tanks.length > 0){
    let tank = tanks[0]
    setName(tank.name)
    setCode(tank.code)
    setId(tank.tankId)
    setIsLoading(false);
  }else{
    createTank().then(tank => {
      setName(tank.name)
      setCode(tank.code)
      setId(tank.tankId)  
      setIsLoading(false);
    })
  }
  setIsLoading(false);
  return tanks
},[createTank])

const getTankCode = async () => {
  const code = await API.get("tanksApi", `/tanks/${id}/source`);
  return code
}
const saveAndRun=  async()=>{
  await saveTank()
  runBot()
}
const runBot = () => {
  setIsLoading(true)
  getTankCode().then((fullCode)=> {
  var canvas = document.getElementById('practice_arena');
    var battle:Battle = new Battle(
        canvas.getContext("2d"),
        canvas.width,
        canvas.height,
        [{name: "boss0", source: bosses.boss0()},{ name: name, source: fullCode }]
    );
    setIsLoading(false)
    battle.run();
  })
} 

useEffect(() => {
  getTanks()
},[getTanks])


  return (
    <>
      <Row >
        <Col align="center">
          <canvas width={600} height={500} id="practice_arena"></canvas>
        </Col>
        <Col align="center">
        <Form>
          <Form.Group controlId="formTankName">
            <Form.Label>Tank Name</Form.Label>
            <Form.Control type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} disabled={disabled || isLoading} />
          </Form.Group>
        </Form>
        <AceEditor
          width={"100%"}
          mode="javascript"
          theme="monokai"
          name="tank_ace_editor"
          fontSize={14}
          onChange={(value) => setCode(value)}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={code}
          disabled={disabled || isLoading}
          setOptions={{
            enableBasicAutocompletion: [customCompleter],
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            useWorker: false,
            tabSize: 2,
        }}/>
              
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col>
          <LoaderButton onClick={saveAndRun}> Save & Run</LoaderButton>
          <LoaderButton onClick={saveTank}> Save </LoaderButton>
          <LoaderButton onClick={runBot}> Run </LoaderButton>
        </Col>
      </Row>
    </>
  );
}

export default Home;