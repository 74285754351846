import React from "react";
import "./Documentation.css";
import { Col, Row } from "react-bootstrap";

export default function Documentation(props) {

  const code = 
  `
  prototype.onIdle = function(){
    this.shoot();
    this.move_forwards(50);
    this.move_backwards(50);
  };
  prototype.onWallCollide = function(){
    this.move_opposide(10);
    this.turn_left(90);
    this.move_forwards(100);
  };
  prototype.onHit = function(){
    this.yell("I am on fire!");
    this.move_backwards(100);
  };
  prototype.onEnemySpot = function(){
    this.yell("Take my bullets!");
    this.shoot();
  };

  `

  return (
    <Row>
        <Col>

            <h2>Javascript</h2>
            <img src="assets/javascript_expression.png" alt="javascript expression" />
            <h2>Events:</h2>
            <ul>
              <li><b>OnIdle</b> - This function is the robot game loop. It will be called every time your robot runs out of actions to do.</li>
              <li><b>OnWallCollide</b> - This event will be called whenever your robot hits a wall.</li>
              <li><b>OnHit</b> - This event will be called whenever a bullet hits your robot.</li>
              <li><b>OnEnemySpot</b> - This even is called when your turret is directly facing an enemy (there seems no reason not to fire!)</li>
            </ul>
            <h2>Sequential Actions:</h2>
            <p>Actions that happen one after the other.</p>
            <ul>
              <li><b>this.turn_left(angle):</b> Turns your tank to the left, <i>params</i>: angle, degrees that the tank will
                be turned</li>
              <li><b>this.turn_right(angle):</b> Turns your tank to the right, <i>params</i>: angle, degrees that the tank
                will be turned</li>
              <li><b>this.move_forwards(distance):</b> Moves your tank forward, <i>params</i>: distance, the distance that
                the tank will move</li>
              <li><b>this.move_backwards(distance):</b> Moves your tank backward, <i>params</i>: distance, the distance that
                the tank will move</li>
              <li><b>this.move_opposide(distance):</b> Moves your tank at the opposite direction <i>params</i>: distance, the
                distance that the tank will move.<br/> if the tank is moving forward it will make the tank move backward
                and vice versa.<br/>
                <b>This action can only be used in OnWallCollide()</b></li>
            </ul>
            <h2>Parallel Actions:</h2>
            <p>Actions that happen at the same time.</p>
            <ul>
              <li><b>this.turn_turret_left(angle):</b> Turns your tank's turret to the left, <i>params</i>: angle, degrees
                that the tank will be turned</li>
              <li><b>this.turn_turret_right(angle):</b> Turns your tank's turret to the right, <i>params</i>: angle, degrees
                that the tank will be turned</li>
              <li><b>this.shoot():</b> Tank shoots one bullet.</li>
              <li><b>this.yell(message):</b> Tank will create a ballon with message, <i>params</i>: message, message to be
                display on the tank ballon</li>
            </ul>
            <h2>Code Example</h2>
            <pre>
                <code>
                    {code}
                </code>
            </pre>

        </Col>
    </Row>
  );
}