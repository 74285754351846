import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import Signup from "./components/Signup";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Documentation from "./components/Documentation";
import ResetPassword from "./components/ResetPassword";
import Arena from "./components/Arena";

export default function Links() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/login/reset"
        element={
          <UnauthenticatedRoute>
            <ResetPassword />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/docs"
        element={
            <Documentation />
        }
      />
      <Route
        path="/assets"
        element={
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/arena"
        element={
          <AuthenticatedRoute>
            <Arena />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <Home />
          </AuthenticatedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />;
   </Routes>
  );
}