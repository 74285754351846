

class Bosses {

    boss0() {
    return `
    (function(){
  var Boss0, tr;
  importScripts('${window.location.href}/assets/js/base-robot.js');

  Boss0 = (function(superclass){
    var prototype = extend$((import$(Boss0, superclass).displayName = 'Boss0', Boss0), superclass).prototype, constructor = Boss0;
    prototype.onIdle = function(){
      this.turn_turret_right(45);
    };
    prototype.onWallCollide = function(){};
    prototype.onHit = function(){
      this.yell("Oops!");
    };
    prototype.onEnemySpot = function(){
      this.yell("Fire!");
      this.shoot();
    };
    function Boss0(){
      Boss0.superclass.apply(this, arguments);
    }
    return Boss0;
  }(BaseRobot));
  tr = new Boss0("Boss 0");
  function extend$(sub, sup){
    function fun(){} fun.prototype = (sub.superclass = sup).prototype;
    (sub.prototype = new fun).constructor = sub;
    if (typeof sup.extended == 'function') sup.extended(sub);
    return sub;
  }
  function import$(obj, src){
    var own = {}.hasOwnProperty;
    for (var key in src) if (own.call(src, key)) obj[key] = src[key];
    return obj;
  }
}).call(this);

`
}
boss1(){
  return `
  // Generated by LiveScript 1.2.0
(function(){
  var Boss1, tr;
  importScripts('${window.location.href}/assets/js/base-robot.js');
  Boss1 = (function(superclass){
    var prototype = extend$((import$(Boss1, superclass).displayName = 'Boss1', Boss1), superclass).prototype, constructor = Boss1;
    prototype.onIdle = function(){
      this.shoot();
      this.move_forwards(50);
      this.move_backwards(50);
      this.turn_turret_right(180);
      this.shoot();
    };
    prototype.onWallCollide = function(){
      this.move_opposide(10);
      this.turn_left(90);
      this.move_forwards(100);
    };
    prototype.onHit = function(){
      this.yell("Oops!");
      this.move_backwards(100);
      this.shoot();
    };
    prototype.onEnemySpot = function(){
      this.yell("Fire!");
      this.shoot();
    };
    function Boss1(){
      Boss1.superclass.apply(this, arguments);
    }
    return Boss1;
  }(BaseRobot));
  tr = new Boss1("Boss 1");
  function extend$(sub, sup){
    function fun(){} fun.prototype = (sub.superclass = sup).prototype;
    (sub.prototype = new fun).constructor = sub;
    if (typeof sup.extended == 'function') sup.extended(sub);
    return sub;
  }
  function import$(obj, src){
    var own = {}.hasOwnProperty;
    for (var key in src) if (own.call(src, key)) obj[key] = src[key];
    return obj;
  }
}).call(this);
  
  `
}

boss2(){
  return `
  // Generated by LiveScript 1.2.0
(function(){
  var Boss2, tr;
  importScripts('${window.location.href}/assets/js/base-robot.js');
  Boss2 = (function(superclass){
    var prototype = extend$((import$(Boss2, superclass).displayName = 'Boss2', Boss2), superclass).prototype, constructor = Boss2;
    prototype.onIdle = function(){
      this.turn_right(10);
      this.turn_turret_right(180);
      this.move_forwards(100);
      if (Math.random() > 0.5) {
        this.turn_right(30);
      } else {
        this.turn_left(30);
      }
    };
    prototype.onWallCollide = function(){
      this.move_opposide(10);
      this.turn_left(90);
    };
    prototype.onHit = function(){
      this.turn_right(30);
      this.move_forwards(50);
    };
    prototype.onEnemySpot = function(){
      this.shoot();
    };
    function Boss2(){
      Boss2.superclass.apply(this, arguments);
    }
    return Boss2;
  }(BaseRobot));
  tr = new Boss2("Boss 2");
  function extend$(sub, sup){
    function fun(){} fun.prototype = (sub.superclass = sup).prototype;
    (sub.prototype = new fun).constructor = sub;
    if (typeof sup.extended == 'function') sup.extended(sub);
    return sub;
  }
  function import$(obj, src){
    var own = {}.hasOwnProperty;
    for (var key in src) if (own.call(src, key)) obj[key] = src[key];
    return obj;
  }
}).call(this);

  `
}
boss3() {
  return `
  // Generated by LiveScript 1.2.0
  (function(){
    var Boss3, tr;
    importScripts('${window.location.href}/assets/js/base-robot.js');
    Boss3 = (function(superclass){
      var idleCount, prototype = extend$((import$(Boss3, superclass).displayName = 'Boss3', Boss3), superclass).prototype, constructor = Boss3;
      idleCount = 0;
      prototype.doSearch = function(){
        this.turn_turret_right(45);
        this.turn_right(15);
        this.move_forwards(25);
      };
      prototype.onIdle = function(){
        var myAngle, forward, tinyMove, tinyShoot, leftDist, rightDist;
        this.idleCount++;
        myAngle = this.me.angle % 360;
        if (this.myVarEnemy) {
          forward = false;
          tinyMove = Math.random() * 45;
          tinyShoot = Math.random() * 10;
          leftDist = myAngle + 360 - this.myVarEnemy[0].angle;
          if (leftDist > 360) {
            leftDist = leftDist - 360;
          }
          rightDist = this.myVarEnemy[0].angle - myAngle;
          if (rightDist < 0) {
            rightDist = 360 + rightDist;
          }
          if (leftDist !== rightDist) {
            if (Math.random() > 0.5) {
              forward = true;
            }
            if (leftDist > rightDist) {
              this.turn_turret_right(rightDist + 5 + tinyShoot);
            } else {
              this.turn_turret_left(leftDist + 5 + tinyShoot);
            }
            if (forward) {
              this.move_forwards(tinyMove);
            } else {
              this.move_backwards(tinyMove);
            }
            this.shoot();
          } else {
            this.shoot();
          }
          this.myVarEnemy = undefined;
        } else {
          if (this.idleCount > 3) {
            this.doSearch();
            if (this.idleCount > 4) {
              this.doSearch();
              if (this.idleCount > 5) {
                this.doSearch();
              }
            }
            return;
          }
          this.turn_turret_left(30);
          this.turn_left(30);
          this.move_forwards(Math.random() * 50 + 10);
        }
      };
      prototype.onWallCollide = function(){
        this.move_opposide(10);
        this.turn_left(90);
        this.idleCount = 0;
      };
      prototype.onHit = function(){
        this.idleCount = 0;
        this.yell("No! I'm hit!");
      };
      prototype.onEnemySpot = function(){
        this.myVarEnemy = this.enemySpot;
        this.shoot();
        this.yell("Enemy spotted!");
        this.idleCount = 0;
      };
      function Boss3(){
        Boss3.superclass.apply(this, arguments);
      }
      return Boss3;
    }(BaseRobot));
    tr = new Boss3("The final boss");
    function extend$(sub, sup){
      function fun(){} fun.prototype = (sub.superclass = sup).prototype;
      (sub.prototype = new fun).constructor = sub;
      if (typeof sup.extended == 'function') sup.extended(sub);
      return sub;
    }
    function import$(obj, src){
      var own = {}.hasOwnProperty;
      for (var key in src) if (own.call(src, key)) obj[key] = src[key];
      return obj;
    }
  }).call(this);
  
`
  }
}

export default Bosses