import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import React, { useEffect, useState } from "react";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./App.css";
import Routes from "./Routes";



function App() {
    const nav = useNavigate();

    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);

    useEffect(() => {
        onLoad();
      }, []);
      
      async function onLoad() {
        try {
          await Auth.currentSession();
          userHasAuthenticated(true);
        } catch (e) {
          if (e !== "No current user") {
            alert(e);
          }
        }
      
        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();
      
        userHasAuthenticated(false);
        nav("/login");
      }
    return (
        !isAuthenticating && (
          <div className="App container py-3">
            <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
              <LinkContainer to="/">
                <Navbar.Brand className="font-weight-bold text-muted">
                  Tank Wars
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                {isAuthenticated ? (
                  <LinkContainer to="/">
                    <Nav.Link>My Tanks</Nav.Link>
                  </LinkContainer>
                ) : ("")}
                <LinkContainer to="/arena">
                  <Nav.Link>Arena</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/docs">
                  <Nav.Link>Documentation</Nav.Link>
                </LinkContainer>
                <Nav activeKey={window.location.pathname}>
                  {isAuthenticated ? (
                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                  ) : (
                    <>
                      <LinkContainer to="/signup">
                        <Nav.Link>Signup</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/login">
                        <Nav.Link>Login</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
              <Routes />
            </AppContext.Provider>
          </div>
        )
      );
  }

export default App;